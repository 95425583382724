<template>
  <!-- Contents -->
  <div class="home-wrapper">
    <div class="screen-ui hide-for-small-only">
        <div class="slide-loader white-bg"></div>
    </div>
    <div class="contents-section valign halign">
      <div class="row expanded">
        <div class="small-12 large-7 columns hide-for-portrait"></div>
        <div class="small-12 large-5 columns">
          <div class="white-space"></div>
          <div class="white-space-large hide-for-landscape"></div>
          <h4 class="content-section-title">
            {{ section }}
          </h4>
          <h3
            v-bind:class="contentClasses"
            v-html="title.replace(/(?:\r\n|\r|\n)/g, '<br />')"
          >
            {{ title }}
          </h3>
          <div class="row">
            <div class="small-12 large-2 columns hide-for-portrait"></div>
            <div class="small-12 large-10 columns">
              <p
                class="content-description"
                v-html="description.replace(/(?:\r\n|\r|\n)/g, '<br />')"
              >
                {{ description }}
              </p>
              <div class="white-space"></div>
              <div class="content-link" v-if="section == 'Contact'">
                <div class="styled-button button">
                  <div class="button-parallax">
                    <a :href="link" class="btn">{{ linkTitle }}</a>
                  </div>
                </div>
              </div>
              <div class="content-link relative" v-else>
                <div class="styled-button button">
                  <div class="button-parallax">
                    <a @click="viewPage(link)">{{ linkTitle }}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-white-bg"></div>
    </div>
  </div>
</template>
<script>
import gsap from "gsap";
import globalObject, { backToHomepage, changePage, run } from "../animate.js";
import { Howl } from "howler";
import loadingJson from "../json/loading.json";
import homeContents from "../json/home.json";

export default {
  name: "Home",
  data() {
    return {
      timeline: gsap.timeline(),
      loaderTimeline: gsap.timeline(),
      global: globalObject,
      islinkclicked: false,
      previousValue: 0,
      loaderTimer: null,
      index: 0,
      section: "Introduction",
      title: "All about Roibot",
      contentClasses: ["content-title", "title-home-color"],
      description:
        "Hi! My real name is Roi Lagunzad and<br>\
                  I love making cool functional and interactive<br>\
                  websites that resonates with people.",
      linkTitle: "About Me",
      link: "about",
      contents: homeContents,
      // Settings
      isOnMobile: false,
      // Audio
      slideIn: new Howl({
        src: ["/resources/sounds/slide-in.mp3"],
        volume: 0.2,
      }),

      loadingScreenMessage: loadingJson,
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  watch: {
    global: {
      handler: function (val, oldVal) {
        val.currentLocation >= 0
          ? this.showSection(val.currentLocation)
          : this.hideSection();
        const percentage = Math.round(val.loadingPercentage);
        document.getElementById("loader-percentage").innerHTML =
          percentage + "/100";

        if (this.previousValue != val.loadingPercentage) {
          gsap.to(".letter-1", 5, {
            yPercent: -60 + (val.loadingPercentage / 100) * 60,
            ease: "expo.out",
          });
          gsap.to(".letter-2", 5, {
            yPercent: -40 + (val.loadingPercentage / 100) * 40,
            ease: "expo.out",
          });
          gsap.to(".letter-3", 5, {
            yPercent: -100 + (val.loadingPercentage / 100) * 100,
            ease: "expo.out",
          });
          gsap.to(".letter-4", 5, {
            yPercent: 90 - (val.loadingPercentage / 100) * 90,
            ease: "expo.out",
          });
          gsap.to(".letter-5", 5, {
            yPercent: 60 - (val.loadingPercentage / 100) * 60,
            ease: "expo.out",
          });
          gsap.to(".letter-6", 5, {
            yPercent: 90 - (val.loadingPercentage / 100) * 90,
            ease: "expo.out",
          });
          this.previousValue = val.loadingPercentage;
        }
        if (val.loadingPercentage == 100) {
          this.loaderTimeline.to(".letter-1", 1, {
            color: "white",
            ease: "expo.out",
          });
          this.loaderTimeline.to(
            ".letter-2",
            1,
            { color: "white", ease: "expo.out" },
            "=-0.99"
          );
          this.loaderTimeline.to(
            ".letter-3",
            1,
            { color: "white", ease: "expo.out" },
            "=-0.99"
          );
          this.loaderTimeline.to(
            ".letter-4",
            1,
            { color: "white", ease: "expo.out" },
            "=-0.99"
          );
          this.loaderTimeline.to(
            ".letter-5",
            1,
            { color: "white", ease: "expo.out" },
            "=-0.99"
          );
          this.loaderTimeline.to(
            ".letter-6",
            1,
            { color: "white", ease: "expo.out" },
            "=-0.99"
          );

          this.loaderTimeline.to(
            ".page-loader .row",
            1,
            { opacity: 0, ease: "expo.out" },
            "=+1"
          );
          this.loaderTimeline.to(
            ".page-loader",
            1,
            { opacity: 0, ease: "expo.inOut" },
            "=-2"
          );

          this.loaderTimeline.to(
            ".page-loader",
            1,
            { xPercent: -100, ease: "expo.out" }
          );
          this.loaderTimeline.to(
            ".page-loader .row",
            0,
            { opacity: 1, ease: "expo.out" }
          );
          this.loaderTimeline.to(
            ".page-loader",
            0,
            { opacity: 1, ease: "expo.inOut" }
          );

          this.loaderTimeline.fromTo(".slide-loader", 0, {xPercent: 100}, {xPercent: 0, ease: "expo.inOut"});
          this.loaderTimeline.fromTo(".slide-loader", 0, {xPercent: 0}, {xPercent: -100, ease: "expo.inOut"});
          this.loaderTimeline.to(".page-loader", 0, {
            xPercent: -100,
            ease: "expo.inOut",
          });
          this.loaderTimeline.to(
            ".intro-tips",
            1,
            { opacity: 1, ease: "expo.inOut"},
            "=+1"
          );
          clearInterval(this.loaderTimer);
        }
      },
      deep: true,
    },
    $route(to, from) {
      if (from.name == "Works" || from.name == "About") {
        window.scrollTo(0, 0);
        this.$router.go();
      }
    },
  },
  methods: {
    showSection: function (index) {
      this.index = `0${index + 1}`;
      this.section = this.contents[index].section;
      this.title = this.contents[index].title;
      this.contentClasses = this.contents[index].contentClasses;
      this.description = this.contents[index].description;
      this.linkTitle = this.contents[index].linkTitle;
      this.link = this.contents[index].link;
      if (this.index == "02") {
        gsap.to(".bottom p", 1, { opacity: 1, ease: "expo.out" });
      }
      this.slideIn.play();
      //this.timeline.to(".transition", 0, {css: {opacity: 0, duration: 0, ease: Expo.easeOut}});
      if (!this.isOnMobile) {
        gsap.to(".menu a", 0, {
          css: { color: "black" },
          duration: 0,
          ease: "expo.out",
        });
      }
      gsap.to(".RETICOOL", 1, { css: { color: "black" }, ease: "expo.out" });

      this.timeline.to(".contents-section", 0, {display: "block"});
      this.timeline.to(".home .contents-section", 0, { opacity: 1 });
      this.timeline.fromTo(
        ".content-section-title",
        2,
        { y: 50, opacity: 0 },
        { y: 0, opacity: 1, ease: "expo.out" }
      );
      this.timeline.fromTo(
        ".content-title",
        2,
        { y: 50, opacity: 0 },
        { y: 0, opacity: 1, ease: "expo.out" },
        "=-1.9"
      );
      this.timeline.fromTo(
        ".content-description",
        2,
        { y: 50, opacity: 0 },
        { y: 0, opacity: 1, ease: "expo.out" },
        "=-1.9"
      );
      this.timeline.fromTo(
        ".content-link",
        2,
        { y: 50, opacity: 0 },
        { y: 0, opacity: 1, ease: "expo.out" },
        "=-1.9"
      );
      if (!this.isOnMobile) {
        this.timeline.fromTo(
          ".content-white-bg",
          0.5,
          { yPercent: 100, xPercent: 28, scaleY: 1 },
          { yPercent: 0, xPercent: 0, scaleY: 1.2, ease: "power2.out", force3D: true },
          "=-2"
        );
      } else {
        this.timeline.fromTo(
          ".content-white-bg",
          0.5,
          { yPercent: 100 },
          { yPercent: 0, ease: "power2.out", force3D: false },
          "=-2"
        );
      }
    },
    hideSection: function () {
      gsap.to(".bottom p", 1, { opacity: 0, ease: "expo.out" });
      gsap.to(".RETICOOL", 1, { css: { color: "white" }, ease: "expo.out" });
      if (!this.isOnMobile) {
        gsap.to(".menu a", 0, { css: { color: "white" } });
        gsap.to(".logo-ul", 0, { css: { color: "white" } });
      }
      this.timeline.to(
        ".content-link",
        1,
        { y: 20, opacity: 0, ease: "expo.in" },
        "=-.9"
      );
      this.timeline.to(
        ".content-description",
        1,
        { y: 20, opacity: 0, ease: "expo.out" },
        "=-.9"
      );
      this.timeline.to(
        ".content-title",
        1,
        { y: 20, opacity: 0, ease: "expo.in" },
        "=-.9"
      );
      this.timeline.to(
        ".content-section-title",
        1,
        { y: 20, opacity: 0, ease: "expo.in" },
        "=-.9"
      );
      this.timeline.to(".home .contents-section", { opacity: 0, duration: 0 });
      this.timeline.to(
        ".content-white-bg",
        1,
        { yPercent: 120, xPercent: 28, ease: "expo.out", force3D: true },
        "=-1"
      );
      this.timeline.to(".contents-section", 0, {display: "none"});
    },
    viewPage: function (page) {
      gsap.to(".logo-ul", 0, { css: { color: "#1c284d" } });
      gsap.to(".bottom", 0.3, { opacity: 0, y: 10, ease: "expo.out" });
      if(!this.isOnMobile){
        this.timeline.to(
          ".content-white-bg",
          3,
          { css: { scale: 3, force3D: true }, ease: "expo.out" },
          "=-0.3"
        );
      }
      else {
        this.timeline.to(
          ".content-white-bg",
          3,
          { css: { scale: 5, force3D: true }, ease: "expo.out" },
          "=-0.3"
        );
      }
      this.timeline.fromTo(
        ".content-link",
        1,
        { y: 0, opacity: 1 },
        { y: 20, opacity: 0, ease: "expo.out" },
        "=-2.9"
      );
      this.timeline.fromTo(
        ".content-description",
        1,
        { y: 0, opacity: 1 },
        { y: 20, opacity: 0, ease: "expo.out" },
        "=-2.9"
      );
      this.timeline.fromTo(
        ".content-title",
        1,
        { y: 0, opacity: 1 },
        { y: 20, opacity: 0, ease: "expo.out" },
        "=-2.9"
      );
      this.timeline.fromTo(
        ".content-section-title",
        1,
        { y: 0, opacity: 1 },
        { y: 20, opacity: 0, ease: "expo.out" },
        "=-2.9"
      );
      this.slideIn.stop();
      changePage();
      //this.timeline.to("#roibot-world", {css: {opacity: 0, duration: 0, ease: "expo.in"}});
      setTimeout(() => this.$router.push({ path: page }).catch(()=>{}), 2000);
    },
    resetLoader: function(){
      gsap.to(".letter-1", 1, { yPercent: 0, color: "rgba(0,0,0,0)", ease: "expo.out" });
      gsap.to(".letter-2", 1, { yPercent: 0, color: "rgba(0,0,0,0)", ease: "expo.out" });
      gsap.to(".letter-3", 1, { yPercent: 0, color: "rgba(0,0,0,0)", ease: "expo.out" });
      gsap.to(".letter-4", 1, { yPercent: 0, color: "rgba(0,0,0,0)", ease: "expo.out" });
      gsap.to(".letter-5", 1, { yPercent: 0, color: "rgba(0,0,0,0)", ease: "expo.out" });
      gsap.to(".letter-6", 1, { yPercent: 0, color: "rgba(0,0,0,0)", ease: "expo.out" });
      gsap.to(".loader-text", 0, { opacity: 0});

      this.timeline.to(".page-loader", 0.5, {xPercent: -100, ease: "expo.inOut"});
      gsap.to(".film-grain",0, { opacity: 0.4, ease: "expo.out"});
    },
    generateLoadingMessage: function () {
      let randomNumber =
        Math.floor(Math.random() * this.loadingScreenMessage.length) + 1;
      document.getElementById(
        "loader-text"
      ).innerHTML = this.loadingScreenMessage[randomNumber];
    },
  },
  mounted() {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
        navigator.userAgent
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        navigator.userAgent.substr(0, 4)
      )
    ) {
      this.isOnMobile = true;
    }
    run();
    window.scrollTo(0, 0);
    console.log(
      "%cNever gonna give you up",
      "padding:1em; color:red; font-size: 20px; font-weight: bold; font-family: 'Gilroy ExtraBold', sans-serif;"
    );
    console.log(
      "%cNever gonna let you down",
      "padding:1em; color:orange; font-size: 20px; font-weight: bold; font-family: 'Gilroy ExtraBold', sans-serif;"
    );
    console.log(
      "%cNever gonna run around and desert you",
      "padding:1em; color:yellow; font-size: 20px; font-weight: bold; font-family: 'Gilroy ExtraBold', sans-serif;"
    );
    console.log(
      "%cNever gonna make you cry",
      "padding:1em; color:green; font-size: 20px; font-weight: bold; font-family: 'Gilroy ExtraBold', sans-serif;"
    );
    console.log(
      "%cNever gonna say goodbye",
      "padding:1em; color:blue; font-size: 20px; font-weight: bold; font-family: 'Gilroy ExtraBold', sans-serif;"
    );
    console.log(
      "%cNever gonna tell a lie and hurt you",
      "padding:1em; color:indigo; font-size: 20px; font-weight: bold; font-family: 'Gilroy ExtraBold', sans-serif;"
    );
    console.log(
      "%c🎵🎵🎵🎵",
      "padding:1em; color:violet; font-size: 20px; font-weight: bold; font-family: 'Gilroy ExtraBold', sans-serif;"
    );

    document.getElementById("about-link").addEventListener("click", () => {
      if(!this.islinkclicked){
        this.islinkclicked = true;
        this.timeline.fromTo(".slide-loader", 1, {xPercent: 100}, {xPercent: 0, ease: "power2.inOut",
            onComplete: () => {
              changePage();
              this.islinkclicked = false;
              this.$router.push({ path: 'about' }).catch(()=>{});
            }
          }
        );
      }
    });
    document.getElementById("work-link").addEventListener("click", () => {
      if(!this.islinkclicked){
        this.islinkclicked = true;
        this.timeline.fromTo(".slide-loader", 1, {xPercent: 100}, {xPercent: 0, ease: "power2.inOut",
            onComplete: () => {
              changePage();
              this.islinkclicked = false;
              this.$router.push({ path: 'works' });
            }
          }
        );
      }
    });
    //loader
    gsap.to(".letter-1", 1, { yPercent: -60, ease: "expo.out" });
    gsap.to(".letter-2", 1, { yPercent: -40, ease: "expo.out" });
    gsap.to(".letter-3", 1, { yPercent: -100, ease: "expo.out" });
    gsap.to(".letter-4", 1, { yPercent: 90, ease: "expo.out" });
    gsap.to(".letter-5", 1, { yPercent: 60, ease: "expo.out" });
    gsap.to(".letter-6", 1, { yPercent: 90, ease: "expo.out" });
    gsap.to("#roibot-world", 0, { css: { display: "block" } });
    gsap.to(".page-loader .loader-text", 2, { css: { display: "block"}, ease: "power2.out"});
    //gsap.to(".home", 0, { css: { overflow: "hidden"}});
    if(this.isOnMobile){
        gsap.to(".menu-link", 0, {display:"none"});
    }
    this.generateLoadingMessage();
    this.loaderTimer = setInterval(() => {
      this.generateLoadingMessage();
    }, 10000);

    gsap.to(".RETICOOL", 1, { css: { color: "white" }, ease: "expo.out" });
    this.timeline.to(".home", 0, { css: { backgroundColor: "rgba(0,0,0,0)" } });
    this.timeline.to(".menu a", 0, { css: { color: "white" } });
    this.timeline.to(".logo-ul", 0, { css: { color: "white" } });

    window.onpopstate = function (event) {
      console.log("back");

      gsap.to(".slide-loader", 0, {xPercent: 100, ease: "power2.inOut"});

      gsap.to(".back-to-world-button", 1, {display: "none", scaleX: 0, scaleY: 0, ease: "expo.inOut", force3D: true });
      this.resetLoader();
      //this.timeline.to(".home .contents-section", 0, {opacity: 1});
      //backToHomepage();
    }.bind(this);
    backToHomepage();
    this.hideSection();

    /* MAGNETIC */
    var mouse = { x: 0, y: 0 };
    var pos = { x: 0, y: 0 };
    var active = false;

    document.addEventListener("mousemove", mouseMove);

    function mouseMove(e) {
      mouse.x = e.clientX;
      mouse.y = e.clientY;
    }

    function updatePosition() {
      if (!active) {
        pos.x += mouse.x - pos.x;
        pos.y += mouse.y - pos.y;
      }
    }

    gsap.ticker.add(updatePosition);

    $(".button").mouseenter(function (e) {
      gsap.to(this, 0.3, { scale: 1.2 });
      active = true;
    });

    $(".button").mouseleave(function (e) {
      gsap.to(this, 0.3, { scale: 1 });
      gsap.to(this.querySelector(".button-parallax"), 0.3, { x: 0, y: 0 });
      active = false;
    });

    $(".button").mousemove(function (e) {
      parallaxCursor(e, this, 3);
      callParallax(e, this);
    });

    function callParallax(e, parent) {
      parallaxIt(e, parent, parent.querySelector(".button-parallax"), 10);
    }

    function parallaxIt(e, parent, target, movement) {
      var boundingRect = parent.getBoundingClientRect();
      var relX = e.clientX - boundingRect.left;
      var relY = e.clientY - boundingRect.top;

      gsap.to(target, 0.3, {
        x: ((relX - boundingRect.width / 2) / boundingRect.width) * movement,
        y: ((relY - boundingRect.height / 2) / boundingRect.height) * movement,
        ease: "power2.out",
      });
    }

    function parallaxCursor(e, parent, movement) {
      var rect = parent.getBoundingClientRect();
      var relX = e.clientX - rect.left;
      var relY = e.clientY - rect.top;
      pos.x = rect.left + rect.width / 2 + (relX - rect.width / 2) / movement;
      pos.y = rect.top + rect.height / 2 + (relY - rect.height / 2) / movement;
    }
  },
  metaInfo() {
      return { 
          title: "Roi Lagunzad - Creative Designer & Front-End Developer",
          meta: [
              { name: 'description', content:  'Step into the imaginative world of Roibot, a Philippine-based Creative Designer and Front-End Developer.'},
              { property: 'og:title', content: "Roi Lagunzad - Creative Designer & Front-End Developer"},
              { property: 'og:site_name', content: 'ROIBOT'},
              {property: 'og:type', content: 'website'},    
              {name: 'robots', content: 'index,follow'} 
          ]
      }
  }
};
</script>
