import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import * as THREE from 'three';
import CameraControls from '../public/js/camera-controls/dist/camera-controls.module.js';
import gsap from 'gsap'
import CANNON, { Vec3 } from 'cannon'
import nipplejs from 'nipplejs'
import {Howl} from 'howler';

CameraControls.install( { THREE: THREE } );

let isOnMobile = false; //initiate as false

// device detection
if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
    || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) { 
    isOnMobile = true;
}

// Coordinates
const globalViewCoord = {
    x: 4.100, 
    y: 11.200, 
    z: 44.480};
const globalViewRot = {
    x: toRadian(-13.60), 
    y: 0, 
    z: 0};

const homeCoord = {
    x: -8.543, 
    y: 5.046, 
    z: 25.348};
const homePoint = {
    x: -8.262, 
    y:  5.877,
    z:  -5.820};

const workCoord = {
    x: 40.200, 
    y: 9.712, 
    z: 8.141};
const workPoint = {
    x: 45.606, 
    y: 0.174, 
    z: -8.273};

const contactCoord = {
    x: 13, 
    y: 4.175, 
    z: 31.942};
const contactPoint = {
    x: 15, 
    y: 2.895, 
    z: 24.802};

const homeCoordMobile = {
    x: -18.543, 
    y: 5.046, 
    z: 23.348};
const homePointMobile = {
    x: -16.262, 
    y:  4.877,
    z:  -5.820};

const workCoordMobile = {
    x: 38.200, 
    y: 5.712, 
    z: 18.141};
const workPointMobile = {
    x: 40.606, 
    y: 5.174, 
    z: -8.273};

const contactCoordMobile = {
    x: 10, 
    y: 5.175, 
    z: 41.942};
const contactPointMobile = {
    x: 11, 
    y: 4.895, 
    z: 34.802};

// Scene
let renderer, scene, camera;

// Layers 
let floorLayer = 0, globalLayer = 1;

// Lights
let ambientFloor, ambientGlobal, hemisphereGlobal, directional, directionalFloor;

// GLTF Loader
let loader;
// Sounds
let brick, bgm, scrape, footstep, ambience;

// Physics
let world;
let roiBodyPhysics;
let dt;
let walls, wallCollisions;
let ball;
let ballBody;
const lettersBody = [];
let collisionGroup1 = 1;
let collisionGroup2 = 2;
let collisionGroup3 = 4;

// Intro
let introY = 0;
// Models
let roi, shaine;
let shaineNeckBone, shaineLastLookAt;
let worldMesh;
let worldFloor, earth, house, work, frontEndDev;
let homePlantLeft, homePlantRight, workRubix, workPlant, contactMailboxDoor;
let cloud1, cloud2;
const lettersMesh = [];

// Particles 
let particles;
const jengaBodyArray = [], jengaArray = [];

// Rendering
let tl = gsap.timeline({paused: false}), roiFaceTl = gsap.timeline({repeat: -1, repeatDelay: 1});
let introTimeline = gsap.timeline({paused: false});
let clock;
let cameraControls;

// Animations
let animations;
let worldAnimationMixer, roiBodyAnimationMixer, roiFaceModel, shaineAnimationMixer;
let roiAnimations,shaineAnimations;
let runSpeedMultiplier = 0.22;

// Controls
let radius = 40;
let joystick;
let joystickPosition;

// Booleans
let isRoiWalking = false;
let isHome = false;
let hasUserEnteredSite = false;
let isPageOpened = false;
let fallOffWorld = 0;
let isHomeExecuted = false;
let isNearWorkExecuted = false;
let isFarWorkExecuted = false;
let isWorkExecuted = false;
let isContactExecuted = false;
let isWorldExecuted = false;
let disableRender = true;
let isFarWork = true;
let isIntroFinished = false;

// Debug
let stats = new Stats();
stats.showPanel( 1 );
//document.body.appendChild( stats.dom );

let globalObject = {
    currentLocation: -1,
    loadingPercentage: 0,
}

// Loader
let manager;

// Scene ============================================================
let buildScene = () => {
    scene = new THREE.Scene();
    scene.fog = new THREE.Fog(0x102287,10, 800);
}

// Let there be gravity (Cannon.JS) ===============================================
let initCannon = () => {
    world = new CANNON.World();

    dt = 1.0/60.0;

    world.broadphase = new CANNON.NaiveBroadphase();
    world.iterations = 10;
    world.gravity.set(0,-9.8,0);
    world.solver.iterations = 50;
    //world.allowSleep = true;
    //world.broadphase.useBoundingBoxes = false;
    //world.defaultContactMaterial.contactEquationStiffness = 5e6;
    world.defaultContactMaterial.contactEquationRelaxation = 3;
    world.doProfiling=true;
}

// Renderer ============================================================
let buildRender = () => {
    const desktopPixelRatio = (window.innerWidth > 1366) ? window.devicePixelRatio : window.devicePixelRatio/2;
    const desktopAntialias = (window.innerWidth > 1366) ? true : false;
    renderer = new THREE.WebGLRenderer({antialias: isOnMobile ? true: true});
    renderer.setClearColor(0xffffff, 1);
    renderer.setPixelRatio(isOnMobile ? window.devicePixelRatio/2 : desktopPixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.autoClear = true;
    renderer.outputEncoding = THREE.sRGBEncoding;
    renderer.physicallyCorrectLights = true;
    renderer.domElement.id = 'roibot-world';
    document.body.appendChild(renderer.domElement);
}

// Camera ============================================================
let buildCamera = () =>  {
    clock = new THREE.Clock();

    camera = new THREE.PerspectiveCamera(
        35,
        window.innerWidth / window.innerHeight,
        0.1,
        1000,
    );
    
    camera.layers.enable(floorLayer); // Floor
    camera.layers.enable(globalLayer); // All

    cameraControls = new CameraControls(camera);
    
    cameraControls.setLookAt((isOnMobile) ? 20: 14.438, 
        13.022, 
        220,
        5,
        0,
        7, 
        true);

    window.addEventListener('resize', () => {
        renderer.setSize(window.innerWidth, window.innerHeight)
        camera.aspect = window.innerWidth / window.innerHeight;

        camera.updateProjectionMatrix();

        cameraControls.setViewport(0,0,window.innerWidth, window.innerHeight);
    });
}

// Let there be light ============================================================
let createSceneLights = () =>  {
    ambientFloor = new THREE.AmbientLight(0x196b05, 3);
    ambientGlobal = new THREE.AmbientLight(0x222222, 1.5);

    hemisphereGlobal = new THREE.HemisphereLight( 0xffffff, 0x94b4ff, 2);
    hemisphereGlobal.position.set(-500.000, 100.000, 100.000);

    directional = new THREE.DirectionalLight(0xb8eaff,1);
    directional.position.set(-500.000, 100.000, 100.000);

    directionalFloor = new THREE.DirectionalLight(0xFFFFFF,0.5, 100);
    directionalFloor.position.set(5.000, 10.000, 7.500);

    scene.add(ambientFloor);
    scene.add(ambientGlobal);
    scene.add(directional);
    scene.add(hemisphereGlobal);
    scene.add(directionalFloor);

    ambientFloor.layers.set(floorLayer);
    ambientGlobal.layers.set(globalLayer);
    hemisphereGlobal.layers.set(globalLayer);
    directional.layers.set(globalLayer);
    directionalFloor.layers.set(floorLayer);
}

// Create Scene Objects ============================================================
let createSceneSubjects = () =>  {

    const draco = new DRACOLoader()
    draco.setDecoderPath('../node_modules/three/examples/js/libs/draco/gltf/');
    draco.setDecoderConfig({ type: 'js' });

    loader = new GLTFLoader(manager);
    loader.setDRACOLoader(draco);
    
    // Load World
    loader.load('resources/models/world_summer.glb', function (gltf) {
        
        // Initialize world
        worldMesh = gltf.scene;
        
        // Initialize animations
        animations = gltf.animations;

        // Initialize Models inside World
        homePlantRight = worldMesh.getObjectByName('HomePlantRight');
        homePlantLeft = worldMesh.getObjectByName('HomePlantLeft');
        workRubix = worldMesh.getObjectByName('WorkRubix');
        workPlant = worldMesh.getObjectByName('WorkPlant');
        contactMailboxDoor = worldMesh.getObjectByName('ContactMailDoor');

        lettersMesh.push(worldMesh.getObjectByName('LetterR'));
        lettersMesh.push(worldMesh.getObjectByName('LetterO'));
        lettersMesh.push(worldMesh.getObjectByName('LetterI'));
        lettersMesh.push(worldMesh.getObjectByName('LetterB'));
        lettersMesh.push(worldMesh.getObjectByName('LetterO2'));
        lettersMesh.push(worldMesh.getObjectByName('LetterT'));

        // Floor
        earth = worldMesh.getObjectByName('Earth');
        worldFloor = worldMesh.getObjectByName('WorldPlane');
        
        // Cloud
        cloud1 = worldMesh.getObjectByName('IntroCloud_1');
        cloud2 = worldMesh.getObjectByName('IntroCloud_2');

        // House
        house = worldMesh.getObjectByName('House');
        console.log(house);
        work = worldMesh.getObjectByName('Work');

        //frontEndDev = worldMesh.getObjectByName('FrontEndDev');

        // Set frustum culled to false
        homePlantRight.children[1].frustumCulled = false;
        homePlantLeft.children[1].frustumCulled = false;
        workRubix.children[1].frustumCulled = false;
        workPlant.children[1].frustumCulled = false;
        contactMailboxDoor.children[1].frustumCulled = false;

        // Initialize animations for moving objects
        worldAnimationMixer = [
            new THREE.AnimationMixer(homePlantRight.children[0]),
            new THREE.AnimationMixer(homePlantLeft.children[0]),
            new THREE.AnimationMixer(workRubix.children[0]),
            new THREE.AnimationMixer(workPlant.children[0]),
            new THREE.AnimationMixer(contactMailboxDoor.children[0]),
        ]
        
        // Add World to Scene
        scene.add(worldMesh);

        // Set World Layer to Global
        worldMesh.traverse(function(object) {
            if ( object.isMesh ) {
                object.layers.set(globalLayer);	
            }
        });

        // Set Floor Layer to Floors
        if (earth != undefined) earth.layers.set(globalLayer);
        if (worldFloor != undefined) worldFloor.layers.set(floorLayer);
        
    }, 
    // called while loading is progressing
	function ( xhr ) {
	},
    
    undefined, function (error) {
        console.error(error);
    });

    // Load Roi
    loader.load('resources/models/roi_update.glb', function (gltf) {
        roi = gltf.scene;

        const roiBoneModel = roi.children[0].children[0];
        roiFaceModel = roi.children[0].children[1].children[2];
        //roiFaceModel.material.map.offset.x = 0.67;

        roiAnimations = gltf.animations;
        roiBodyAnimationMixer = new THREE.AnimationMixer(roiBoneModel);
        roiBodyAnimationMixer.clipAction(roiAnimations[0]).play();
        roiBodyAnimationMixer.timeScale = 1;

        // Add Roi to Scene
        scene.add(roi);

        roi.position.y = 0.1;
        roi.position.set(2,2,5);
        roi.rotation.y = toRadian(20);
        roi.scale.set(1,1,1)

        // Set Mood
        setCharacterMood(roiFaceModel, "happy");         
        
        // Set Roi Layer to Global
        roi.traverse(function(object) {
            object.layers.set(globalLayer);
        });
        
        // Add physics to roi
        const roiBodyShape = new CANNON.Sphere(1.5);

        roiBodyPhysics = new CANNON.Body({
            mass: 500, 
            collisionFilterGroup: collisionGroup3, 
            collisionFilterMask: collisionGroup1 | collisionGroup2,
            material: new CANNON.Material()
        });
        roiBodyPhysics.addShape(roiBodyShape);
        roiBodyPhysics.position.set(roi.position.x, roi.position.y, roi.position.z);
        roiBodyPhysics.velocity.set(0,0,0);
        roiBodyPhysics.angularVelocity.set(0,0,0);
        roiBodyPhysics.restitution = 0;
        roiBodyPhysics.contactEquationRelaxation = 1000;
        world.add(roiBodyPhysics);
        
    }, 
    // called while loading is progressing
	function ( xhr ) {

	},
    undefined, function (error) {
        console.error(error);
    });
    
    // Load Shaine
    loader.load('resources/models/shaine.glb', function (gltf) {
        shaine = gltf.scene;

        const shaineModel = shaine.children[0];
        shaineNeckBone = shaine.getObjectByName("NeckBone");

        //console.log(shaineNeckBone);
        // Initialize animations
        
        shaineAnimations = gltf.animations;
        shaineAnimationMixer = new THREE.AnimationMixer(shaineModel.children[0]);
        // Add Shaine to Scene
        scene.add(shaine);

        shaine.position.y = 0.1;
        shaine.position.set(-16.6,-0.3,-3);
        shaine.rotation.y = toRadian(40);
        shaine.scale.set(1,1,1)

        // Set Shaine Layer to Global
        shaine.traverse(function(object) {
            object.layers.set(globalLayer);
        });

    }, 
    // called while loading is progressing
	function ( xhr ) {
	},
    
    undefined, function (error) {
        console.error(error);
    });

    // Add Particles
    const vertices = [];

    for ( let i = 0; i < 100; i ++ ) {
        const x = THREE.MathUtils.randFloatSpread( 150 );
        const y = THREE.MathUtils.randFloatSpread( 350 );
        const z = THREE.MathUtils.randFloatSpread( 550 );

        vertices.push( x, y, z );
    }
    let email = "hellow@roibot.dev";

    const geometry = new THREE.BufferGeometry();
    geometry.setAttribute( 'position', new THREE.Float32BufferAttribute( vertices, 4 ) );

    const material = new THREE.PointsMaterial( { color: 0xffffff } );

    particles = new THREE.Points( geometry, material );
    
    scene.add( particles );
    
    particles.layers.set(globalLayer);
    particles.position.z = 200;
}

let initSounds = () => {
    brick = new Howl({
        src: ['/resources/sounds/brick.mp3'],
        volume: 0.1,
    });
    scrape = new Howl({
        src: ['/resources/sounds/brick.mp3'],
        volume: 0.05,
    });
    footstep = new Howl({
        src: ['/resources/sounds/footstep.mp3'],
        volume: 0.2,
        loop: true
    });
    bgm = new Howl({
        src: ['/resources/sounds/Lately Lui - Chasing Clouds.mp3'],
        volume: 0.6,
        autoplay: true,
        loop: true,
    });

    ambience = new Howl({
        src: ['/resources/sounds/ambience.mp3'],
        autoplay: true,
        loop: true,
        volume: 0.06
    });
    
    bgm.pos(42, -4, 1);
}
// Control Objects ============================================================
let controlSubjects = () => {
    
    let joystickOption = {
        zone: document.getElementById('roibot-world'),
        mode: 'dynamic',
        size: radius*2,
        restOpacity: 0.4,
        color: '#216f95'
    };
    
    joystick = nipplejs.create(joystickOption);

    joystick.on('start move end dir plain', function(evt, data) {
        if(!disableRender) {
            joystickPosition = data;
            isRoiWalking = true;
            if(!hasUserEnteredSite) {
                gsap.to(".intro-tips", 1, {css: {opacity: 0, display: "none"}, ease: "expo.Out"});
                //gsap.to(frontEndDev.position, 1, {z: -5, ease: "sine.in"});
            }
            hasUserEnteredSite = true;
            
            roiBodyAnimationMixer.clipAction(roiAnimations[0]).stop();
            roiBodyAnimationMixer.clipAction(roiAnimations[1]).play();

            footstep.rate(1.1);
            if(!footstep.playing()){
                footstep.play(); 
            }  
        }
    }).on('end', function(evt, data) {
        if(!disableRender) {
            joystickPosition = data;
            isRoiWalking = false;

            roiBodyAnimationMixer.clipAction(roiAnimations[1]).stop();
            roiBodyAnimationMixer.clipAction(roiAnimations[0]).play();
            roiBodyAnimationMixer.timeScale = 1;

            roiBodyPhysics.velocity.set(0, 0, 0);
            roiBodyPhysics.angularVelocity.set(0, 0, 0);
            roiBodyPhysics.quaternion.set(0,0,0,1);
            footstep.stop();
        }
    });

    window.addEventListener('mousemove', onMouseMove, false);

    function onMouseMove(event){
        if(!hasUserEnteredSite) 
            cameraControls.setLookAt(14.438 + (event.clientX - window.innerWidth / 2)/13, 
                23.022 +  + (event.clientY - window.innerHeight / 2)/10, 
                220,
                5,
                0,
                7, 
                true);
    }
}

// Render ============================================================
let animate = () =>  {
    requestAnimationFrame(animate);
    
    if(!disableRender) {
        stats.begin();
        render();
        stats.end();
    }
    
}

let render = () => {
	const delta = clock.getDelta();
	const updated = cameraControls.update(delta);
    
    renderer.autoClear = true;

    camera.layers.set(floorLayer); 
    renderer.render(scene, camera);

    renderer.autoClear = false;

    camera.layers.set(globalLayer); 
    renderer.render(scene, camera);

    particles.rotation.x -= 0.001;
    particles.rotation.y -= 0.0005;
    
    // Update cannon.js physics
    updatePhysics(delta);
    
    if (worldAnimationMixer) {
        for(let i = 0, j = worldAnimationMixer.length; i < j; ++i){
            worldAnimationMixer[i].update(delta);
        }
    }

    if (roiBodyAnimationMixer) {
        roiBodyAnimationMixer.update(delta);
    }

    if (shaineAnimationMixer) {
        shaineAnimationMixer.update(delta);
    }
    
    if(!isIntroFinished && (cloud1 != undefined)) {
        isIntroFinished = true;
        introTimeline.to({}, {
            duration: 2,
            onUpdate() {
                scene.fog.far = (this.progress() * 200);

                cloud1.position.x = 0;
                cloud2.position.x = 0;
                cameraControls.setLookAt((isOnMobile) ? 20: 14.438, 
                    13.022, 
                    280 + 220,
                    5,
                    0,
                    7, 
                    true);
            },
            ease: "expo.in"
        });
        introTimeline.to({}, {
            duration: 2,
            onUpdate() {
                cloud1.position.x = (this.progress() * -100);
                cloud2.position.x = (this.progress() * 100);
                scene.fog.far = 200 + (this.progress() * 800);
                cameraControls.setLookAt((isOnMobile) ? 20: 14.438, 
                    13.022, 
                    280 - (this.progress() * 280) + 220,
                    5,
                    0,
                    7, 
                    true);

            },
            ease: "expo.in"
        });
        introTimeline.to(scene.fog.color, 2, {r: 16/255, g:34/255, b:135/255},"=-2");
    }
    if (!isPageOpened && hasUserEnteredSite) {
        if (isRoiWalking) {
            bgm.pos(40 - roi.position.x, 1, roi.position.z + 5);
            // Rotate Character to facing direction
            if (joystickPosition.angle.radian !=  undefined) {
                roi.rotation.y = joystickPosition.angle.radian + toRadian(90);
            }
    
            // Move Character to facing direction
            if (joystickPosition.distance !=  undefined) {
                const vx = (runSpeedMultiplier * joystickPosition.distance) * Math.cos(joystickPosition.angle.radian);
                const vz = (runSpeedMultiplier * joystickPosition.distance) * Math.sin(joystickPosition.angle.radian);
    
                roiBodyPhysics.velocity.x = vx;
                roiBodyPhysics.velocity.z = -vz;
                
                roiBodyAnimationMixer.timeScale = joystickPosition.distance / 20;
            }
    
            // Move camera slightly to facing direction
            if (joystickPosition.direction !=  undefined) {
                if (joystickPosition.direction.x == "left"){
                    if(isOnMobile){
                        cameraControls.setLookAt(roi.position.x + 12, 
                            roi.position.y + 30, 
                            roi.position.z + 70,
                            roi.position.x, 
                            roi.position.y + 6, 
                            roi.position.z,
                            true);
                    }
                    else {
                        cameraControls.setLookAt(roi.position.x, 
                            roi.position.y + 17, 
                            roi.position.z + 45,
                            roi.position.x - 3, 
                            roi.position.y + 5, 
                            roi.position.z,
                            true);
                    }
                }
                if (joystickPosition.direction.x == "right"){
                    if(isOnMobile){
                        cameraControls.setLookAt(roi.position.x - 12, 
                            roi.position.y + 30, 
                            roi.position.z + 70,
                            roi.position.x, 
                            roi.position.y + 6, 
                            roi.position.z,
                            true);
                    }
                    else {
                        cameraControls.setLookAt(roi.position.x, 
                            roi.position.y + 17, 
                            roi.position.z + 45,
                            roi.position.x + 3, 
                            roi.position.y + 5, 
                            roi.position.z,
                            true);
                    }
                }
            } 
        }
        /*
        if(!isRoiWalking) {

            roiBodyPhysics.velocity.set(0, 0, 0);
            roiBodyPhysics.angularVelocity.set(0, 0, 0);
            roiBodyPhysics.quaternion.set(0,0,0,1);
            footstep.stop();
        }*/
        // If inside Home
        if(roi != null){
    
            // Prevent shaine from turning into The Ring
            if(roi.position.x > -19 && roi.position.z > -2){
                shaineNeckBone.lookAt(new THREE.Vector3(roi.position.x, 2.3, roi.position.z));
                shaineLastLookAt = new THREE.Vector3(roi.position.x, 2.3, roi.position.z);
            }
    
            shaineNeckBone.lookAt(shaineLastLookAt);
    
            if (roi.position.x < -9.6 && roi.position.z < 5.3){
                cameraControls.setLookAt(isOnMobile? homeCoordMobile.x : homeCoord.x, 
                    isOnMobile? homeCoordMobile.y : homeCoord.y, 
                    isOnMobile? homeCoordMobile.z : homeCoord.z, 
                    isOnMobile? homePointMobile.x : homePoint.x, 
                    isOnMobile? homePointMobile.y : homePoint.y, 
                    isOnMobile? homePointMobile.z : homePoint.z, 
                    true);
                
                setBackgroundColor("about");
                if(!isHome){
                    
                    shaineAnimationMixer.clipAction(shaineAnimations[0]).play();
    
                    isHome = true;
                }
            }
            if (roi.position.x > 27 && isFarWork){
                setBackgroundColor("near-work");
                isFarWork = false;
            }
            if (roi.position.x < 27 && !isFarWork){

                setBackgroundColor("far-work");
                isFarWork = true;
            }
            // If inside Work
            if (roi.position.x > 36 && roi.position.z < -3){
                cameraControls.setLookAt(isOnMobile? workCoordMobile.x : workCoord.x, 
                    isOnMobile? workCoordMobile.y : workCoord.y, 
                    isOnMobile? workCoordMobile.z : workCoord.z, 
                    isOnMobile? workPointMobile.x : workPoint.x, 
                    isOnMobile? workPointMobile.y : workPoint.y, 
                    isOnMobile? workPointMobile.z : workPoint.z,
                    true);
                setBackgroundColor("work");
            }
        
            // If inside Contact
            if ((roi.position.x > 9 && roi.position.x < 18)
                && 
                (roi.position.z > 16 && roi.position.z < 28)){

                cameraControls.setLookAt(isOnMobile? contactCoordMobile.x : contactCoord.x, 
                    isOnMobile? contactCoordMobile.y : contactCoord.y, 
                    isOnMobile? contactCoordMobile.z : contactCoord.z, 
                    isOnMobile? contactPointMobile.x : contactPoint.x, 
                    isOnMobile? contactPointMobile.y : contactPoint.y, 
                    isOnMobile? contactPointMobile.z : contactPoint.z, 
                    true);

                setBackgroundColor("contact");
            }
            
            if(!(roi.position.x < -9.6 && roi.position.z < 5.3) && 
                !(roi.position.x > 36 && roi.position.z < -3) &&
                !((roi.position.x > 9 && roi.position.x < 18)
                && 
                (roi.position.z > 16 && roi.position.z < 27))) {
                setBackgroundColor("world"); 
                shaineAnimationMixer.clipAction(shaineAnimations[0]).stop();
            }
        }    
    }
    
}

let updatePhysics = (delta) =>  {
    // Step the physics world
    world.step(dt, delta, 10);

    if (roi != undefined) {
        roi.position.x = roiBodyPhysics.position.x;
        roi.position.z = roiBodyPhysics.position.z;
        roi.position.y = (roiBodyPhysics.position.y)-2.11; // Fixed y angle
        if(roi.position.y < -100) {
            
            roi.position.set(12,2,5);
            roiBodyPhysics.position.set(12,2,5);

            cameraControls.setLookAt(isOnMobile? roi.position.x + 12 : roi.position.x, 
                isOnMobile? roi.position.y + 30 : roi.position.y + 25, 
                isOnMobile? roi.position.z + 70 : roi.position.z + 45,
                isOnMobile? roi.position.x : roi.position.x - 3, 
                isOnMobile? roi.position.y + 6 : roi.position.y + 1, 
                isOnMobile? roi.position.z : roi.position.z,
                true);

            fallOffWorld += 1;

            if(fallOffWorld > 3) {
                setCharacterMood(roiFaceModel, "annoyed");
            }
        }
    }   

    ball.position.copy(ballBody.position);
    ball.quaternion.copy(ballBody.quaternion);
    
    if (!isOnMobile){
        for(let i = 0, j = jengaArray.length; i < j; ++i){
            jengaArray[i].position.copy(jengaBodyArray[i].position);
            jengaArray[i].quaternion.copy(jengaBodyArray[i].quaternion);
        }
    }
    /*
    if (lettersMesh != undefined){
        for(let i = 0, j = lettersMesh.length; i < j; ++i){
            lettersMesh[i].position.x = lettersBody[i].position.x;
            lettersMesh[i].position.z = lettersBody[i].position.z - .8;
            lettersMesh[i].position.y = (lettersBody[i].position.y) - 0.11;
            lettersMesh[i].quaternion.copy(lettersBody[i].quaternion);
        }
    }*/
}

// Physics ===================================================================
let addPhysics = () => {
    const ballShape = new CANNON.Sphere(1, 64, 64);
    ballBody = new CANNON.Body({
        mass: 50, 
        collisionFilterGroup: collisionGroup2, 
        collisionFilterMask: collisionGroup1 | collisionGroup3 | collisionGroup2,
        //velocity: 0.1,
        material: new CANNON.Material()
    });

    ballBody.addShape(ballShape);
    ballBody.linearDamping = 0.01;
    ballBody.angularDamping = 0.01;
    ballBody.position.set(15,1.1,-13.5);
    
    world.add(ballBody);

    const contactMaterial = new CANNON.ContactMaterial(world, ballBody, { friction: 0.1, restitution: 1.7 });

    world.addContactMaterial(contactMaterial);

    const geometry = new THREE.SphereGeometry(1);
    const material = new THREE.MeshStandardMaterial( {color: 0xdddddd} );

    ball = new THREE.Mesh( geometry, material );
    scene.add(ball);
    ball.layers.set(globalLayer);

    walls = [];
    wallCollisions = [];

    walls.push(
        new THREE.Mesh(
            new THREE.BoxBufferGeometry(9,20,0.5),
            material
        )
    );

    walls.push(
        new THREE.Mesh(
            new THREE.BoxBufferGeometry(20,20,25),
            material
        )
    );

    walls.push(
        new THREE.Mesh(
            new THREE.BoxBufferGeometry(1,20,55),
            material
        )
    );

    walls.push(
        new THREE.Mesh(
            new THREE.BoxBufferGeometry(6,20,5),
            material
        )
    );

    walls.push(
        new THREE.Mesh(
            new THREE.BoxBufferGeometry(6,20,3),
            material
        )
    );

    walls.push(
        new THREE.Mesh(
            new THREE.BoxBufferGeometry(3,20,3),
            material
        )
    );

    walls.push(
        new THREE.Mesh(
            new THREE.BoxBufferGeometry(6,20,1),
            material
        )
    );

    walls.push(
        new THREE.Mesh(
            new THREE.BoxBufferGeometry(2,20,6),
            material
        )
    );

    // signs
    walls.push(
        new THREE.Mesh(
            new THREE.BoxBufferGeometry(1,20,1),
            material
        )
    );

    walls.push(
        new THREE.Mesh(
            new THREE.BoxBufferGeometry(1,20,1),
            material
        )
    );

    walls.push(
        new THREE.Mesh(
            new THREE.BoxBufferGeometry(1,20,1),
            material
        )
    );

    walls.push(
        new THREE.Mesh(
            new THREE.BoxBufferGeometry(2,20,2),
            material
        )
    );

    walls.push(
        new THREE.Mesh(
            new THREE.BoxBufferGeometry(.5,20,.5),
            material
        )
    );

    walls.push(
        new THREE.Mesh(
            new THREE.BoxBufferGeometry(7,20,5),
            material
        )
    );

    walls.push(
        new THREE.Mesh(
            new THREE.BoxBufferGeometry(5,20,5),
            material
        )
    );

    // Floors
    walls.push(
        new THREE.Mesh(
            new THREE.BoxBufferGeometry(70,1,60),
            material
        )
    );

    walls.push(
        new THREE.Mesh(
            new THREE.BoxBufferGeometry(15,1,30),
            material
        )
    );

    walls.push(
        new THREE.Mesh(
            new THREE.BoxBufferGeometry(8,1,9),
            material
        )
    );

    walls.push(
        new THREE.Mesh(
            new THREE.BoxBufferGeometry(12,1,7),
            material
        )
    );

    walls.push(
        new THREE.Mesh(
            new THREE.BoxBufferGeometry(50,1,6),
            material
        )
    );

    walls[0].position.set(-17,0,-4.2);
    walls[0].rotation.y = toRadian(43);
    walls[1].position.set(-14,0,-23);
    walls[2].position.set(-25.3,0,-7.2);
    walls[3].position.set(3.5,0,-25.2);
    walls[4].position.set(19,0,-21);
    walls[5].position.set(30,0,-19);
    walls[6].position.set(39.5,0,-8);
    walls[7].position.set(42.5,0,-6);
    walls[8].position.set(15,0,-6);
    walls[9].position.set(-5.3,0,12.5);
    walls[10].position.set(29,0,13);
    walls[11].position.set(13,0,18);
    walls[12].position.set(31.7,0,-7);
    walls[13].position.set(30,0,24);
    walls[14].position.set(47,0,13);
    // floor
    walls[15].position.set(0.7,0,-5);
    walls[16].position.set(43,0,-2);
    walls[17].position.set(38,0,-20);
    walls[18].position.set(42,0,18);
    walls[19].position.set(3.7,0,24);

    for(let i = 0, j = walls.length; i < j; ++i){
        const object = new THREE.Box3().setFromObject(walls[i]);
        const width = object.max.x - object.min.x;
        const height = object.max.y - object.min.y;
        const depth = object.max.z - object.min.z;

        const wallShape = new CANNON.Box(new CANNON.Vec3(width/2, height/2, depth/2));
        const wall = new CANNON.Body({
            mass: 0, 
            collisionFilterGroup: collisionGroup1,
            collisionFilterMask: collisionGroup2 | collisionGroup3,
            material: new CANNON.Material()
        });

        wall.addShape(wallShape);

        const rot = new CANNON.Vec3(1,0,0)

        wall.quaternion.setFromAxisAngle(rot,(Math.PI/2))
        wall.position.copy(walls[i].position);
        wall.quaternion.copy(walls[i].quaternion);

        world.add(wall);

        wallCollisions.push(wall);        
    }
    
    const size = 0.3;
    const mass = 1;
    const gap = 0.1;
    
    // Add playground
    if (!isOnMobile){
        for (let x = 0; x < 2; x++){
            for(let i=0; i<((x*2) + 4); i++){ // Layers
                for(let j=0; j<(x+3); j++){
                    const body = new CANNON.Body({ 
                        mass: mass,
                        collisionFilterGroup: collisionGroup2, 
                        collisionFilterMask: collisionGroup1 | collisionGroup2 | collisionGroup3,
                    });

                    let color = 0xa291c0;
                    
                    const he = new CANNON.Vec3(size, size, size * 3);

                    let dz = 1;

                    if(i % 2 === 0){
                        dz = 0;
                    }

                    if((i+j) % 3 == 0){
                        color = 0xd0c4e5;
                    }

                    const shape = new CANNON.Box(he);

                    body.addShape(shape);
                    body.position.set(
                        0 + (x * 3),
                        (2 * (size + gap) * i) + 1,
                        ((2 * ((size*3) + gap) * j) + dz) + -15,
                    );
        
                    world.addBody(body);
                    jengaBodyArray.push(body);
                    
                    const jengaMesh = new THREE.Mesh(
                        new THREE.BoxBufferGeometry(size*2, size*2, (size * 3)*2),
                        new THREE.MeshStandardMaterial( {color: color} )
                    );

                    jengaArray.push(jengaMesh);

                    body.addEventListener("collide",function(e){
                        const relativeVelocity = e.contact.getImpactVelocityAlongNormal();
                        if(hasUserEnteredSite){
                            if(Math.abs(relativeVelocity) > 10){
                                brick.play();
                            } 
                            if(Math.abs(relativeVelocity) > 5 && Math.abs(relativeVelocity) < 10){
                                scrape.play();
                            } 
                        }
                    });
                }
            }
        }

        for(let i = 0, j = jengaArray.length; i < j; ++i){
            scene.add(jengaArray[i]);
            jengaArray[i].layers.set(globalLayer);jengaArray[i].addEventListener("collide",function(e){
                const relativeVelocity = e.contact.getImpactVelocityAlongNormal();

                if(hasUserEnteredSite){
                    if(Math.abs(relativeVelocity) > 10){
                        brick.play();
                    }
                }
            });
        }
    }
    // Add Letters
    for(let j=1; j<=6; j++){
        const body = new CANNON.Body({ 
            mass: mass,
            collisionFilterGroup: collisionGroup2, 
            collisionFilterMask: collisionGroup1 | collisionGroup3 | collisionGroup2,
        });
        const shape = new CANNON.Box(new CANNON.Vec3(1,.5,1));

        body.addShape(shape);
        
        body.position.set(
            -8 + (j * 4),
            10,
            9
        );

        const rot = new CANNON.Vec3(1,0,0)

        body.quaternion.setFromAxisAngle(rot,(Math.PI/2))
        world.add(body);
        lettersBody.push(body);

        body.addEventListener("collide",function(e){
            const relativeVelocity = e.contact.getImpactVelocityAlongNormal();

            if(hasUserEnteredSite){
                if(Math.abs(relativeVelocity) > 10){
                    brick.play();
                } 
                if(Math.abs(relativeVelocity) > 5 && Math.abs(relativeVelocity) < 10){
                    scrape.play();
                } 
            }
        });
    }

    walls = [];
}

let setBackgroundColor = (location) => {
    if(location == "about" && !isHomeExecuted){
        tl.to(ambientFloor.color, 1.5, {r: 115/255, g:238/255, b:96/255});
        tl.to(scene.fog.color, 1.5, {r: 61/255, g:155/255, b:255/255},"=-1.5");
        tl.to(hemisphereGlobal.groundColor, 1.5, {r: 109/255, g:204/255, b:245/255},"=-1.5");
        
        if(isOnMobile){
            tl.to(scene.fog, 1, {far: 100, near: 0},"=-1.5");
        }
        else {
            tl.to(scene.fog, 1, {far: 80, near: 30},"=-1.5");
        }

        worldAnimationMixer[0].clipAction(animations[0]).play();
        worldAnimationMixer[1].clipAction(animations[1]).play();

        // Animate website content
        globalObject.currentLocation = 0;
        isHomeExecuted = true;
        isWorldExecuted = false;
    }
    if(location == "work" && !isWorkExecuted){
        tl.to(ambientFloor.color, 2, {r: 100/255, g:92/255, b:188/255});
        tl.to(scene.fog.color, 1.5, {r: 94/255, g:86/255, b:212/255},"=-1.5");
        tl.to(hemisphereGlobal.groundColor, 1.5, {r: 183/255, g:95/255, b:225/255},"=-1.5");
        if(isOnMobile){
            tl.to(scene.fog, 1, {far: 90, near: 0},"=-1.5");
        }
        else {
            tl.to(scene.fog, 1, {far: 60, near: 0},"=-1.5");
        }

        worldAnimationMixer[2].clipAction(animations[2]).play();
        worldAnimationMixer[3].clipAction(animations[3]).play();

        // Animate website content
        globalObject.currentLocation = 1;
        isWorkExecuted = true;
        isWorldExecuted = false;
    }
    if(location == "near-work" && !isNearWorkExecuted){
        tl.to(house.scale, 1, {x: 0, y: 0, z: 0, ease: "sine.in"});
        tl.to(house.position, 1, {y: -5, ease: "sine.in"},"=-1");
        tl.fromTo(work.position, 2.2, {y: -5, ease: "sine.in"},{y: 1.6, ease: "expo.out"},"=-1");
        isNearWorkExecuted = true;
        isFarWorkExecuted = false;
    }
    if(location == "far-work" && !isFarWorkExecuted){
        tl.to(house.scale, 1, {x: 1, y: 1, z: 1, ease: "sine.in"});
        tl.to(house.position, 1, {y: 50.83372497558594, ease: "sine.in"},"=-1");
        isFarWorkExecuted = true;
        isNearWorkExecuted = false;
    }
    if(location == "contact" && !isContactExecuted){
        tl.to(ambientFloor.color, 1.5, {r: 108/255, g:45/255, b:178/255});
        tl.to(scene.fog.color, 1.5, {r: 228/255, g:73/255, b:109/255},"=-1.5");
        
        if(isOnMobile){
            tl.to(scene.fog, 1, {far: 60, near:20},"=-1.5");
        }
        else {
            tl.to(scene.fog, 1, {far: 50, near: 10},"=-1.5");
        }

        worldAnimationMixer[4].clipAction(animations[4]).play();

        // Animate website content
        globalObject.currentLocation = 2;
        isContactExecuted = true;
        isWorldExecuted = false;
    }

    if(location == "world" && !isWorldExecuted){
        tl.to(ambientFloor.color, 2, {r: 67/255, g:138/255, b:50/255});
        tl.to(scene.fog.color, 2, {r: 48/255, g:107/255, b:242/255},"=-2");
        tl.to(hemisphereGlobal.groundColor, 1.5, {r: 202/255, g:148/255, b:255/255},"=-1.5");

        if(isOnMobile){
            tl.to(scene.fog, 1.5, {far: 300, near: 20},"=-2");
        }
        else {
            tl.to(scene.fog, 1.5, {far: 200, near: 20},"=-2");
        }

        isHome = false;
        
        if(worldAnimationMixer != undefined){
            for(let i = 0, j = worldAnimationMixer.length; i < j; ++i){
                worldAnimationMixer[i].clipAction(animations[i]).stop(); 
            }
        }

        // Animate website content
        globalObject.currentLocation = -1;
        isHomeExecuted = false;
        isWorkExecuted = false;
        isContactExecuted = false;
        isWorldExecuted = true;
    }
}
let setCharacterMood = (facemodel, mood) => {
    if(mood == "happy"){
        roiFaceTl.add(function(){
            facemodel.material.map.offset.x = 0;
        }, "+=0.15");
        roiFaceTl.add(function(){
            facemodel.material.map.offset.x = 0.335;
        }, "+=0.03");
        roiFaceTl.add(function(){
            facemodel.material.map.offset.x = 0.668;
        }, "+=0.03");
        roiFaceTl.add(function(){
            facemodel.material.map.offset.x = 0.335;
        }, "+=0.06");
        roiFaceTl.add(function(){
            facemodel.material.map.offset.x = 0;
        }, "+=0.1");
        roiFaceTl.add(function(){
            facemodel.material.map.offset.x = 0;
        }, "+=2");
    }
    if(mood == "sad"){
        roiFaceTl.add(function(){
            facemodel.material.map.offset.x = 0;
            facemodel.material.map.offset.y = 0.335;
        }, "+=0.15");
        roiFaceTl.add(function(){
            facemodel.material.map.offset.x = 0.334;
            facemodel.material.map.offset.y = 0.335;
        }, "+=0.03");
        roiFaceTl.add(function(){
            facemodel.material.map.offset.x = 0.6665;
            facemodel.material.map.offset.y = 0.335;
        }, "+=0.03");
        roiFaceTl.add(function(){
            facemodel.material.map.offset.x = 0.334;
            facemodel.material.map.offset.y = 0.335;
        }, "+=0.06");
        roiFaceTl.add(function(){
            facemodel.material.map.offset.x = 0;
            facemodel.material.map.offset.y = 0.335;
        }, "+=0.1");
        roiFaceTl.add(function(){
            facemodel.material.map.offset.x = 0;
            facemodel.material.map.offset.y = 0.335;
        }, "+=2");
    }
    if(mood == "annoyed"){
        roiFaceTl.add(function(){
            facemodel.material.map.offset.x = 0;
            facemodel.material.map.offset.y = 0.675;
        }, "+=0.15");
        roiFaceTl.add(function(){
            facemodel.material.map.offset.x = 0.334;
            facemodel.material.map.offset.y = 0.675;
        }, "+=1.5");
        roiFaceTl.add(function(){
            facemodel.material.map.offset.x = 0;
            facemodel.material.map.offset.y = 0.675;
        }, "+=5.15");
    }
}

// Run  ============================================================
document.addEventListener('DOMContentLoaded', function() {
    buildScene();
    buildRender();
    buildCamera();
    initCannon();
    createSceneLights();
    createSceneSubjects();
    addPhysics();
    controlSubjects();
    animate(); 
});

// Math functions
function toRadian(degrees){
    return degrees * THREE.Math.DEG2RAD;
}

// MAIN SITE JS ===================================================
/* EXPORT */

export default globalObject; 
export function changePage() {
    disableRender = true;
    bgm.stop();
    ambience.stop();
}
export function backToHomepage(){
    disableRender = false;
    if(bgm != undefined){
        bgm.play();
    }
    if(ambience != undefined){
        ambience.play();   
    }
}

export function run() {
    manager = new THREE.LoadingManager();
    manager.onProgress = function ( item, loaded, total ) {
        const percentage = (loaded/total) * 100;
        globalObject.loadingPercentage = percentage;
        if(percentage > 95) {
            initSounds();
        }
    };
}